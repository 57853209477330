import {SelectDic} from '../../RequestPort/equipment/line'
import {equipmentPage,SaveEquipment,Setcheckpoint,deleteCheckpoint} from "../../RequestPort/equipment/equipment";
import {selectDepart} from '../../RequestPort/user/user'
export default {
    name: "equipment",
    data(){
        return {
            PollingDataRules: {
                equipmentName: [
                    {required: true, message: '请输入设备名称', trigger: 'blur'},
                ],
                equipmentType:[
                    {required: true, message: '请选择设备类型', trigger: 'change'},
                ],
                equipmentSpecifications:[
                    {required: true, message: '请输入设备规格型号', trigger: 'blur'},
                ],
                equipmentStatus:[
                    {required: true, message: '请选择设备状态', trigger: 'change'},
                ],
                equipmentTime:[
                    {required: true, message: '请选择生产日期', trigger: 'change'},
                ],
                equipmentUseTime:[
                    {required: true, message: '请选择使用日期', trigger: 'change'},
                ],
                equipmentDepartment:[
                    {required: true, message: '请选择所属部门', trigger: 'change'},
                ],
                equipmentProducers:[
                    {required: true, message: '请输入生产商', trigger: 'blur'},
                ],
                equipmentHome:[
                    {required: true, message: '请选择位置', trigger: 'change'},
                ],
            },
            CanSeeDetailBool:false,
            SearchDeviceData:'',
            Map:{},
            UserStatus:[
                {name:'停用',value:0},
                {name:'在用',value:1},
                {name:'废弃',value:2}
            ],
            SelectData:{
                current:1,
                equipmentStatus:1,
                equipmentType:'',
                searchText:'',
                size: 100,
            },
            DeviceTypeArray:[],
            DevicePositionArray:[],
            DrawPointStatus:false,
            NewPollingBool:false,
            DeviceDetail:false,
            PollingData:{
                checkingPoint: 1,
                equipmentDepartment: "",
                equipmentDepartmentId: 0,
                equipmentHome: "",
                equipmentName: "",
                equipmentProducers: "",
                equipmentRemark: "",
                equipmentSpecifications: "",
                equipmentStatus: '',
                equipmentTime: "",
                equipmentType:'',
                equipmentUrl: "",
                equipmentUseTime: "",
                lat: 0,
                lng: 0
            },
            usestatusArray:[
                {name:'停用',value:0},
                {name:'在用',value:1},
                {name:'废弃',value:2},
            ],
            geocoder:{},
            departMentArray:[],
            options:[],
            markers:[],
            SelectDeviceData:[],
            tourSelectData:{
                ids:[],
                setType:0,
            },
            selectMarkerData:{},
            infoWindow:{},
        }
    },
    watch:{
        DrawPointStatus(val){
            if(val){
                this.Map.clearMap()
                this.mouseTool.marker({})
                this.geocoder = new AMap.Geocoder({});
                this.mouseTool.on('draw', (event)=>{
                    this.Map.clearMap()
                    let marker = new AMap.Marker({
                        position: [event.obj.Ce.position.lng,event.obj.Ce.position.lat],
                        offset: new AMap.Pixel(-13, -30)
                    });
                    this.geocoder.getAddress([event.obj.Ce.position.lng,event.obj.Ce.position.lat],(status,result)=>{
                        this.PollingData.equipmentHome = result.regeocode.formattedAddress
                        this.PollingData.lat = event.obj.Ce.position.lat
                        this.PollingData.lng = event.obj.Ce.position.lng
                    })
                    marker.setMap(this.Map);
                })
                this.NewPollingBool = val
            }else{
                this.mouseTool.close()
                this.NewPollingBool = val
                this.equipmentFun()
                this.PollingData = {
                    checkingPoint: 1,
                    equipmentDepartment: "",
                    equipmentDepartmentId: 0,
                    equipmentHome: "",
                    equipmentName: "",
                    equipmentProducers: "",
                    equipmentRemark: "",
                    equipmentSpecifications: "",
                    equipmentStatus: '',
                    equipmentTime: "",
                    equipmentType:'',
                    equipmentUrl: "",
                    equipmentUseTime: "",
                    lat: 0,
                    lng: 0
                }
            }
        },
    },
    mounted(){
        window.ChangePointData = (e)=>{
            this.ChangePointData()
        }
        window.deleteCheckpointFun = (e)=>{
            this.deleteCheckpointFun()
        }
        selectDepart({}).then((e)=>{
            this.departMentArray = e.data
        })
        SelectDic({parentCode:20000}).then((e)=>{
            this.DeviceTypeArray = e.data
            this.equipmentFun()
        })
        this.Map = new AMap.Map("map", {
            center: [116.434381, 39.898515],
            zoom: 14
        });
        this.mouseTool = new AMap.MouseTool(this.Map)
    },
    methods:{
        SearchFun(){
            this.equipmentFun()
        },
        canceltourPoint(){
            if(this.SelectDeviceData.length == 0){
                this.$message.error('请先选择数据')
                return
            }
            this.tourSelectData.setType = 0
            let DeviceArray = []
            this.SelectDeviceData.forEach((item,index)=>{
                DeviceArray.push(item.id)
            })
            this.tourSelectData.ids = DeviceArray
            Setcheckpoint(this.tourSelectData).then((e)=>{
                this.equipmentFun()
                this.this.tourSelectData.ids = []
            })
        },
        settourPoint(){
            if(this.SelectDeviceData.length == 0){
                this.$message.error('请先选择数据')
                return
            }
            let DeviceArray = []
            this.tourSelectData.setType = 1
            this.SelectDeviceData.forEach((item,index)=>{
                DeviceArray.push(item.id)
            })
            this.tourSelectData.ids = DeviceArray
            Setcheckpoint(this.tourSelectData).then((e)=>{
                this.equipmentFun()
                this.tourSelectData.ids = []
            })
        },
        DeviceSelectFun(e){
            this.SelectDeviceData = e
        },
        CancelPollingData(){
            this.Map.clearMap()
            this.equipmentFun()
            this.NewPollingBool = false
            this.DrawPointStatus = false
        },
        equipmentFun(){
            equipmentPage(this.SelectData).then((e)=>{
                e.data.records.forEach((item,index)=>{
                    let marker =  new AMap.Marker({
                        map: this.Map,
                        position:[item.lng,item.lat],
                        extData:item,
                    });
                    this.DeviceTypeArray.forEach((item1,index1)=>{
                        if(item1.code == item.equipmentType){
                            item.equipmentTypeName = item1.name
                        }
                    })
                    marker.on('click',(e)=>{
                        let info = []
                        this.selectMarkerData = e.target.getExtData()
                        this.infoWindow = new AMap.InfoWindow({
                            content:["    <div  style=\"text-align: center;width:100%;\">",
                                e.target.getExtData().equipmentName,
                                "    </div>",
                                "    <div class='PopupAmap'>",
                                "      类型：",e.target.getExtData().equipmentTypeName,
                                "    </div>",
                                "    <div class='PopupAmap'>",
                                "      状态：",e.target.getExtData().StatusName,
                                "    </div>",
                                "    <div class='PopupAmap'>",
                                "      生产厂商：",e.target.getExtData().equipmentProducers,
                                "    </div>",
                                "    <div class='PopupAmap'>",
                                "      地址：",e.target.getExtData().equipmentHome,
                                "    </div>",
                                "    <div class='PopupAmap' style='text-align: center'>",
                                "       <button onclick='ChangePointData()' class='el-button el-button--default el-button--mini'>修改</button>",
                                "       <button onclick='deleteCheckpointFun()' class='el-button el-button--default el-button--mini'>删除</button>",
                                "    </div>"].join(""),
                            offset:new AMap.Pixel(0,-20)
                        });
                        this.infoWindow.open(this.Map,[e.target.getExtData().lng,e.target.getExtData().lat]);
                    })
                    this.markers.push(marker)
                    switch(item.equipmentStatus) {
                        case 0:
                            item.StatusName = '停用'
                            break;
                        case 1:
                            item.StatusName = '在用'
                            break;
                        case 2:
                            item.StatusName = '废弃'
                            break;
                    }
                })
                this.DevicePositionArray = e.data.records
            })
        },
        ChangePointData(e){
            this.DrawPointStatus = true
            // selectMarkerData
            this.PollingData = this.selectMarkerData
            setTimeout((e)=>{
                let marker =  new AMap.Marker({
                    map: this.Map,
                    position:[this.selectMarkerData.lng,this.selectMarkerData.lat],
                    extData:this.selectMarkerData,
                });
            },1)
        },
        deleteCheckpointFun(){
            deleteCheckpoint({id:this.selectMarkerData.id}).then((e)=>{
                this.Map.clearMap()
                this.equipmentFun()
                this.infoWindow.close()
                this.$message.success('删除成功')
            })
        },
        SavePollingData(){
            this.$refs['PollingRef'].validate((valid) => {
                if (valid) {
                    this.PollingData.equipmentDepartment = this.PollingData.equipmentDepartment[this.PollingData.equipmentDepartment.length-1]
                    SaveEquipment(this.PollingData).then((e)=>{
                        this.Map.clearMap()
                        this.equipmentFun()
                        this.$message.success('添加成功')
                        this.NewPollingBool = false
                        this.DrawPointStatus = false
                    })
                } else {
                    return false;
                }
            });


        },
        handleChange(e){

        },
        NewPollingPoint(){
            this.DrawPointStatus = !this.DrawPointStatus
        },
        DeviceRowClick(e){
            this.Map.setCenter([e.lng,e.lat])
        },
        CanSeeLineDetail(){
            this.CanSeeDetailBool = !this.CanSeeDetailBool
        },
    },
}