import axios from '@/common/js/request'
///zypatrol/facility/equipment/page
export function equipmentPage(params){
    const equipmentPage = axios({
        url: 'patrol/facility/equipment/page',
        method: 'get',
        params
    })
    return equipmentPage
}

//zypatrol/facility/equipment/update
export function SaveEquipment(data){
    const SaveEquipment = axios({
        url: 'patrol/facility/equipment/update',
        method: 'post',
        data
    })
    return SaveEquipment
}
//zypatrol/facility/equipment/checkpoint/set
export function Setcheckpoint(data){
    const Setcheckpoint = axios({
        url: 'patrol/facility/equipment/checkpoint/set',
        method: 'post',
        data
    })
    return Setcheckpoint
}
//zypatrol/facility/equipment/delete
export function deleteCheckpoint(data){
    const deleteCheckpoint = axios({
        url: 'patrol/facility/equipment/delete',
        method: 'post',
        data
    })
    return deleteCheckpoint
}